import { VisibilityOff, Visibility } from "@mui/icons-material";
import {
  Box,
  InputAdornment,
  IconButton,
  Checkbox,
  styled,
  Typography,
  Alert,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import { StyledButton } from "../common/StyledComponents/StyledButton/StyledButton";
import { StyledTextField } from "../common/StyledComponents/StyledTextField/StyledTextField";
import { useForm } from "react-hook-form";
import { serviceSignUp } from "../../api/service/authServices";
import { AxiosError, AxiosResponse } from "axios";
import { serviceFetchPages } from "../../api/service/services";

const StyledCheckbox = styled(Checkbox)({
  color: "rgba(15, 14, 150, 1)",

  "&.Mui-checked": {
    color: "rgba(15, 14, 150, 1)",
  },
});

type Props = {
  handleClose: CallableFunction;
  setSuccessMessage: CallableFunction;
  handleCloseLogin: CallableFunction;
};

const SignUpForm: React.FC<Props> = (props: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false); // Add loading state
  const [isChecked, setIsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const alertErrorMessage = (errorMessage: any) => {
    setErrorMessage(errorMessage);
  };

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
    setErrorMessage("");
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const onSubmit = async (data: any) => {
    if (isChecked) {
      setLoading(true);
      await serviceSignUp(data)
        .then((response: AxiosResponse) => {
          props?.setSuccessMessage(response?.data?.message);
          props.handleClose(true);
          setLoading(false);
        })
        .catch((error: AxiosError<any>) => {
          console.log("Error:", error?.response?.data?.message);
          alertErrorMessage(error?.response?.data?.message);
          setLoading(false);
        });
    } else {
      setErrorMessage(
        "Please accept the Terms and Conditions to create an account."
      );
    }
  };

  const fetchTermsAndConditions = async () => {
    await serviceFetchPages()
      .then((response: AxiosResponse) => {
        const termsAndConditions = response?.data?.find((page: any) =>
          /terms-and-conditions/i.test(page.slug)
        );
        if (termsAndConditions) {
          //  props.handleCloseLogin();
          //    props.handleClose(true);
          /*    navigate(`/${termsAndConditions?.slug}`, {
            state: {
              title: termsAndConditions?.title?.rendered,
              content: termsAndConditions?.content?.rendered,
            }, 
          });*/

          const state = {
            title: termsAndConditions?.title?.rendered,
            content: termsAndConditions?.content?.rendered,
          };
          localStorage.setItem("termsAndConditions", JSON.stringify(state));
          const url = `/${termsAndConditions?.slug}`;
          window.open(`${url}`, "_blank");
        }
      })
      .catch((error: AxiosError<any>) => {
        console.error("Error fetching pages:", error);
      });
  };

  return (
    <div>
      <Box
        component="form"
        noValidate
        sx={{ px: 3 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        {errorMessage && (
          <Alert
            severity="error"
            sx={{
              fontFamily: "Poppins",
              fontWeight: 600,
              padding: "0",
              marginBottom: "10px",
              backgroundColor: "#FFF",
              color: "#d32f2f",
              alignItems: "center",
            }}
          >
            {errorMessage}
          </Alert>
        )}
        <StyledTextField
          {...register("name")}
          margin="dense"
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoComplete="name"
          size="small"
          error={Boolean(errors?.name)}
          helperText={errors?.name ? errors?.name?.message?.toString() : " "}
          InputLabelProps={{
            style: { fontSize: "1rem" }, // Change the size of the input label shrink
          }}
          //  variant="filled"
        />
        <StyledTextField
          {...register("email", {
            required: {
              value: true,
              message: "Email is Required",
            },
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Please enter a valid email",
            },
          })}
          required
          margin="dense"
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          size="small"
          error={Boolean(errors?.email)}
          helperText={errors?.email ? errors?.email?.message?.toString() : " "}
          InputLabelProps={{
            style: { fontSize: "1rem" }, // Change the size of the input label shrink
          }}
          //  variant="filled"
        />
        <StyledTextField
          {...register("password", { required: "Password is required" })}
          margin="dense"
          required
          fullWidth
          name="password"
          label="Password"
          id="password"
          autoComplete="current-password"
          size="small"
          type={showPassword ? "text" : "password"}
          error={Boolean(errors?.password)}
          helperText={
            errors?.password ? errors?.password?.message?.toString() : " "
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            style: { fontSize: "1rem" }, // Change the size of the input label shrink
          }}
          // variant="filled"
        />

        <div style={{ display: "flex", alignItems: "center" }}>
          <StyledCheckbox
            checked={isChecked}
            onChange={handleCheckboxChange}
            size="small"
          />
          <Typography
            className="signup-text"
            gutterBottom
            sx={{ fontFamily: "Poppins" }}
          >
            I agree to the{" "}
            <span
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={fetchTermsAndConditions}
            >
              Terms and Conditions
            </span>
          </Typography>
        </div>
        <StyledButton
          type="submit"
          sx={{ mt: 1, mb: 2 }}
          disabled={loading ? true : false}
        >
          {loading ? <CircularProgress size={24} /> : "Create account"}
        </StyledButton>
      </Box>
    </div>
  );
};

export default SignUpForm;
