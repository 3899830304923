import { MenuProps, Menu } from "@mui/material";
import styled from "styled-components";

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "120px",
    height: "auto",
    padding: " 10.7px",
    textAlign: "center",
    zIndex: 10000,
    justifyContent: "center",
    display: "flex",
    // linear gradient rounder border
    background:
      "linear-gradient(white, white) padding-box,linear-gradient(180deg, #D3131D 0%, #160F92 100%) border-box",
    borderRadius: "0.5em",
    border: "0.25px solid transparent",

    // This is working for linear border gradient but border radius is not affected

    /*  borderImage: "linear-gradient(180deg, #D3131D 0%, #160F92 100%)",
    borderImageSlice: "1", */

    boxShadow: "0px",

    overflow: "hidden",
    "&::-webkit-scrollbar": {
      borderRadius: "6.5px",
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ffffff",
      marginRight: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor:
        "linear-gradient(269.62deg, rgba(201, 19, 34, 0.1) -52.82%, rgba(15, 14, 150, 0.1) 13.35%, rgba(255, 20, 0, 0.1) 114.52%)",

      borderRadius: "6.5px",
      width: "6px",
      marginRight: "10px",
      transform: "matrix(-1, 0, 0, 1, 0, 0)",
    },

    "& .MuiMenu-list": {
      padding: "1px",
    },
    "& .MuiDivider-root": {
      marginTop: 0,
      marginBottom: 0,
      // border: "0.25px solid rgba(0, 0, 0, 0.3)",
    },
    "& .MuiMenuItem-root": {
      width: "auto",
      height: "40px",
      minHeight: "36px",
      // margin: ' 6.1px 5.4px 6.2px 10px',
      "& .MuiTypography-root": {
        fontFamily: "Poppins",
        fontSize: "15px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0em",
        textAlign: "center",
      },

      color: "rgba(15, 14, 150, 1)",
      "& .MuiAvatar-root": {
        width: "27.9px",
        height: " 28px",
        marginRight: "10px",
      },
      "& + .MuiDivider-root": {
        width: "216.8px",

        marginTop: 0,
        marginBottom: 0,
        border: "solid 0.25px #f5f6fa",
      },

      "&:focus-visible": {
        borderRadius: "6px",
        border: "solid 1px",
        backgroundColor:
          "linear-gradient(269.62deg, rgba(201, 19, 34, 0.1) -52.82%, rgba(15, 14, 150, 0.1) 13.35%, rgba(255, 20, 0, 0.1) 114.52%)",
      },

      "&:hover": {
        borderRadius: "6px",
        backgroundColor:
          "linear-gradient(269.62deg, rgba(201, 19, 34, 0.1) -52.82%, rgba(15, 14, 150, 0.1) 13.35%, rgba(255, 20, 0, 0.1) 114.52%)",
      },
      "&:active": {
        borderRadius: "6px",
        border: "solid 1px",
        backgroundColor:
          "linear-gradient(269.62deg, rgba(201, 19, 34, 0.1) -52.82%, rgba(15, 14, 150, 0.1) 13.35%, rgba(255, 20, 0, 0.1) 114.52%)",
      },
    },
  },
}));
