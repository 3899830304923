import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Alert,
  Link,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import "../Login/LoginModal.css";
import "./ForgotPassword.css";
import { useForm } from "react-hook-form";

import pp_logo from "../../assets/pp-logo-new.svg";
import { StyledModal } from "../common/StyledComponents/StyledModal/StyledModal";
import { CloseRounded, Visibility, VisibilityOff } from "@mui/icons-material";
import { StyledButton } from "../common/StyledComponents/StyledButton/StyledButton";
import { StyledTextField } from "../common/StyledComponents/StyledTextField/StyledTextField";
import {
  serviceChangePassword,
  serviceVerifyEmail,
} from "../../api/service/authServices";
import { AxiosResponse, AxiosError } from "axios";

export type Props = {
  email: string;
  openModalVerifyCode: boolean;
  handleCloseVerifyCode: CallableFunction;
  handleCloseForgotPassword: CallableFunction;
};

const ChangePassword: React.FC<Props> = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();
  const [isErrorMessage, setIsErrorMessage] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const [successMessage, setSuccessMessage] = React.useState<string>();
  const [loading, setLoading] = React.useState(false); // Add loading state
  const [timer, setTimer] = useState(120);
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});
  const refs = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;

    if (!/^[0-9]$/.test(value)) {
      setValidationErrors((prev) => ({
        ...prev,
        [`code${index}`]: "Please enter a valid number",
      }));
    } else {
      setValidationErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[`code${index}`];
        return newErrors;
      });

      if (index < refs.current.length - 1) {
        refs.current[index + 1]?.focus();
      }
    }
  };
  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const formatTimer = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const handleResendCode = async () => {
    await serviceVerifyEmail(props?.email)
      .then((response: AxiosResponse) => {
        setTimer(120);
      })
      .catch((error: AxiosError<any>) => {
        console.log("Error:", error.response?.data);
        setErrorMessage(error.response?.data.error);
      });
  };

  const handleClose = () => {
    reset();
    setErrorMessage("");
    props.handleCloseVerifyCode(false);
    setIsErrorMessage(false);
  };

  const onSubmit = async (data: any) => {
    const code = `${data.code1}${data.code2}${data.code3}${data.code4}`;

    const info = {
      email: props?.email,
      password: data.confirmPassword,
      otp: code,
    };
    setLoading(true);

    await serviceChangePassword(info)
      .then((response: AxiosResponse) => {
        setErrorMessage("");
        setSuccessMessage("Password updated successfully!!");
        reset();
        setLoading(false);
        setTimeout(() => {
          setSuccessMessage("");
          props.handleCloseVerifyCode(false);
          props.handleCloseForgotPassword(false);
        }, 3000);
      })
      .catch((error: AxiosError<any>) => {
        console.log("Error:", error.response?.data);
        setLoading(false);
        setErrorMessage(error.response?.data.error);
      });
  };

  return (
    <div>
      <StyledModal
        open={props.openModalVerifyCode}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={"login-container"} sx={{ height: "450px" }}>
          <Grid container display="flex" flexDirection="column">
            <Grid item xs={12} md={12} lg={12}>
              <div
                className="flex-row"
                style={{ marginBottom: isErrorMessage ? 0 : 5 }}
              >
                <IconButton
                  disableRipple
                  aria-label="price-pinger-logo-label"
                  sx={{
                    padding: 0,
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                >
                  <img
                    id="price-pinger-logo"
                    alt="Price Pinger Logo"
                    src={pp_logo}
                    height={"31px"}
                    width={"31px"}
                  ></img>
                </IconButton>
                <Typography className="welcome-text" gutterBottom>
                  Change Password
                </Typography>
                <IconButton
                  disableRipple
                  aria-label="close modal"
                  sx={{
                    padding: 0,
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                  onClick={handleClose}
                >
                  <CloseRounded />
                </IconButton>
              </div>
            </Grid>
            {errorMessage && (
              <Alert
                severity="error"
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 600,
                  marginBottom: "10px",
                  backgroundColor: "#FFF",
                  color: "#d32f2f",
                  alignItems: "center",
                  padding: "0px 24px",
                }}
              >
                {errorMessage}
              </Alert>
            )}
            {successMessage && (
              <Alert
                severity="success"
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 600,
                  padding: "0",
                  marginBottom: "10px",
                  backgroundColor: "#FFF",
                  alignItems: "center",
                }}
              >
                {successMessage}
              </Alert>
            )}

            <Grid item xs={12} md={12} lg={12}>
              <Box
                component="form"
                noValidate
                sx={{ px: 3, py: 1 }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <StyledTextField
                  {...register("newPassword", {
                    required: {
                      value: true,
                      message: "New Password is required",
                    },
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters long",
                    },
                    pattern: {
                      value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$#&*]{8,}$/,
                      message:
                        "Password must contain at least one number and one letter",
                    },
                  })}
                  required
                  margin="dense"
                  fullWidth
                  id="newPassword"
                  label="New Password"
                  name="newPassword"
                  autoComplete="new-password"
                  type={showPassword ? "text" : "password"}
                  size="small"
                  error={Boolean(errors?.newPassword)}
                  helperText={
                    errors?.newPassword
                      ? errors?.newPassword?.message?.toString()
                      : " "
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <StyledTextField
                  {...register("confirmPassword", {
                    required: {
                      value: true,
                      message: "Confirm Password is required",
                    },
                    validate: (value) =>
                      value === watch("newPassword") ||
                      "Passwords do not match",
                  })}
                  required
                  margin="dense"
                  fullWidth
                  id="confirmPassword"
                  label="Confirm Password"
                  name="confirmPassword"
                  autoComplete="confirm-password"
                  type={showConfirmPassword ? "text" : "password"}
                  size="small"
                  error={Boolean(errors?.confirmPassword)}
                  helperText={
                    errors?.confirmPassword
                      ? errors?.confirmPassword?.message?.toString()
                      : " "
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Typography className="subtitle-text" gutterBottom>
                  Enter the code sent to <b>{props?.email}</b> below:
                </Typography>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  display="flex"
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  gap="2"
                >
                  {[1, 2, 3, 4].map((index) => (
                    <Grid item xs={2} key={index}>
                      <StyledTextField
                        {...register(`code${index}`, {
                          required: {
                            value: true,
                            message: "Code is required",
                          },
                          pattern: {
                            value: /^[0-9]$/,
                            message: "Please enter a valid number",
                          },
                        })}
                        inputRef={(el) => (refs.current[index] = el)}
                        required
                        margin="dense"
                        fullWidth
                        id={`code${index}`}
                        name={`code${index}`}
                        autoComplete={`code${index}`}
                        size="small"
                        error={Boolean(
                          Object.keys(validationErrors).length > 0
                        )}
                        helperText={
                          index === 1 &&
                          Object.keys(validationErrors).length > 0
                            ? "Please enter valid numbers in all fields"
                            : " "
                        }
                        inputProps={{
                          maxLength: 1,
                          style: { textAlign: "center" },
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChange(e, index)
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
                <Typography
                  className="resend-code-text"
                  sx={{ textAlign: "right" }}
                >
                  {timer > 0 ? (
                    <span className="timer-text">
                      Send code again: {formatTimer(timer)}
                    </span>
                  ) : (
                    <Link
                      component="button"
                      sx={{
                        fontSize: "12px",
                        color: "#0F0E96",
                        fontFamily: "Poppins",
                      }}
                      onClick={() => handleResendCode()}
                    >
                      Resend Code
                    </Link>
                  )}
                </Typography>
                <StyledButton
                  type="submit"
                  sx={{ mt: 1, mb: 1 }}
                  disabled={loading ? true : false}
                >
                  {loading ? <CircularProgress size={24} /> : "Submit"}
                </StyledButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </StyledModal>
    </div>
  );
};

export default ChangePassword;
