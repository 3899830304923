import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import pp_logo from "../../assets/pricePingerLogo.svg";
import { Avatar, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import "./Header.css";
import { Link } from "react-router-dom";
//import Search from "../common/Search/Search";
import { StyledMenu } from "../common/StyledComponents/StyledMenu/StyledMenu";
import { StyledTooltip } from "../common/StyledComponents/StyledTooltip/StyledTooltip";
import LoginModal from "../Login/LoginModal";
import { logout } from "../../api/axiosInstance";
import { CircularProgress } from "@mui/material";
import { DragHandle } from "@mui/icons-material";
import { configs } from "../../api/config";

const pages = [
  { nav: "Top Deals", navLink: "top-deals-section" },
  { nav: "Blogs", navLink: "blogs-section" },
];

const settings = ["My wishlist"];

export default function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [openLoginModal, setOpenLoginModal] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = React.useState(false);

  //const [searchQuery, setSearchQuery] = useState<string>("");

  const theme = useTheme();

  const fontSize = {
    xs: theme.typography.pxToRem(14),
    sm: theme.typography.pxToRem(14),
    md: theme.typography.pxToRem(16),
  };

  let displayName = localStorage.getItem("displayName") || "";
  displayName = displayName?.split(" ")[0];

  useEffect(() => {
    if (displayName !== "") setIsUserLoggedIn(!!displayName);
  }, [displayName]);

  useEffect(() => {}, [isUserLoggedIn]);

  useEffect(() => {}, [openLoginModal]);

  /* 
  useEffect(() => {
    console.log("Searching query", searchQuery);
  }, [searchQuery]); */

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  /*  const handleSearch = (query: string) => {
    setSearchQuery(query);
    console.log("searching for ", query);
  };
 */
  const handleLoginStatus = async () => {
    handleCloseUserMenu();
    setOpenLoginModal(true);
  };

  const handleLogOutStatus = async () => {
    /* localStorage.clear();
    window.location.reload(); */
    setIsLoading(true);
    // Call your logout API here
    setTimeout(() => {
      // Simulating API call
      logout();
      setIsLoading(false);
      setIsUserLoggedIn(false);
      handleCloseUserMenu();
    }, 2000); // Set timeout to simulate API call duration

    handleCloseUserMenu();
  };

  const handleCloseLoginModal = () => {
    setOpenLoginModal(false);
  };

  return (
    <AppBar
      position="static"
      id="header-toolbar"
      sx={{
        backgroundColor: "white",
        color: "black",
        boxShadow: "none",
        fontFamily: "Poppins Regular",
      }}
    >
      <Container maxWidth={"xl"}>
        <Toolbar disableGutters>
          <IconButton
            disableRipple
            aria-label="price-pinger-logo-label"
            sx={{
              display: { xs: "none", md: "flex" },
              mr: 1,
              marginLeft: "auto",
              "&:hover": { backgroundColor: "transparent" },
              padding: { xs: "0px" },
            }}
            component={Link}
            to="/home"
          >
            <img
              id="price-pinger-logo"
              alt="Price Pinger Logo"
              src={pp_logo}
              className="responsive-logo"
            ></img>
          </IconButton>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              id="customized-nav-button"
              size="medium"
              aria-label="nav-menu"
              aria-controls="menu-appbar-nav"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <DragHandle />
            </IconButton>
            <StyledMenu
              id="menu-appbar-nav"
              anchorEl={anchorElNav}
              MenuListProps={{
                "aria-labelledby": "customized-nav-button",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
                fontSize: { xs: "12px", md: "12px", lg: "15px" },
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {pages.map((page, i) => (
                <div style={{ textAlign: "center" }}>
                  <MenuItem
                    key={`menu-item${page.nav}`}
                    onClick={handleCloseNavMenu}
                    sx={{ justifyContent: "center" }}
                  >
                    <Link
                      to={`/home#${page.navLink}`}
                      className="menu-link-styles"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        textAlign="center"
                        className="menu-item-styles"
                      >
                        {page.nav}
                      </Typography>
                    </Link>
                  </MenuItem>
                  <Divider key={`${i}-div`} />
                </div>
              ))}
              <MenuItem
                key={`menu-item-plugin-link`}
                sx={{ justifyContent: "center" }}
              >
                <Link
                  // to={`/home#${page.navLink}`}
                  to="#"
                  style={{ textDecoration: "none" }}
                  onClick={() =>
                    window.open(configs.grap_the_plugin_url, "_blank")
                  }
                >
                  <Typography
                    textAlign="center"
                    className="grab-the-plugin-style"
                  >
                    Add Extension
                  </Typography>
                </Link>
              </MenuItem>
            </StyledMenu>
          </Box>
          <IconButton
            disableRipple
            aria-label="price-pinger-logo-label"
            sx={{
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              mr: 1,
              marginLeft: "auto",
              "&:hover": { backgroundColor: "transparent" },
            }}
            component={Link}
            to="/home"
          >
            <img
              id="price-pinger-logo"
              alt="Price Pinger Logo"
              src={pp_logo}
              className="responsive-logo"
            ></img>
          </IconButton>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            {pages.map((page, index) => (
              <React.Fragment key={page.nav}>
                <Button
                  key={`button${page.nav}`}
                  href={`#${page.navLink}`}
                  className="menu-item-button-styles"
                  sx={{
                    my: 2,
                    mr: index === pages.length - 1 ? 4 : 0, // Add margin right to the last item
                  }}
                  component={Link}
                  to={`/home#${page.navLink}`}
                >
                  {page.nav}
                </Button>
                {index < pages.length - 1 && (
                  <Divider
                    orientation="vertical"
                    sx={{
                      mx: 1,
                      height: "20px",
                      position: "relative",
                      top: "25px",
                    }}
                  />
                )}
              </React.Fragment>
            ))}
          </Box>
          {/* <Box sx={{ flexGrow: 0, mr: 1, ml: 1 }}>
            <Search SearchText={"Search"} handleSearch={handleSearch} />
          </Box> */}
          <Box
            sx={{
              flexGrow: 0,
              display: { xs: "none", sm: "none", md: "block" },
            }}
          >
            <div>
              <Button
                id="plugin_link"
                variant="contained"
                className="save_item"
                sx={{
                  fontSize: fontSize,
                  fontWeight: 700,
                  whiteSpace: "nowrap",
                  "&.Mui-disabled": {
                    color: "#fff",
                  },
                }}
                onClick={() =>
                  window.open(configs.grap_the_plugin_url, "_blank")
                }
              >
                Add Extension
              </Button>
            </div>

            {/*  <SearchByName SearchText={"Search.."} handleSearch={handleSearch} /> */}
          </Box>
          {isUserLoggedIn ? (
            <Box
              sx={{
                flexGrow: 0,
                mr: 1,
                ml: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                textAlign="center"
                className="display-name-styles"
                sx={{ display: { xs: "none", sm: "none", md: "block" } }}
              >
                {displayName || "Guest"}
              </Typography>

              <StyledTooltip title="Open settings">
                <IconButton
                  key="user-menu"
                  id="customized-avatar-menu"
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0 }}
                >
                  {isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Avatar src="/static/images/avatar/2.jpg" />
                  )}
                </IconButton>
              </StyledTooltip>
              <StyledMenu
                sx={{ mt: 1 }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                MenuListProps={{
                  "aria-labelledby": "customized-avatar-menu",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting, i) => (
                  <div style={{ textAlign: "center" }}>
                    {i !== 0 && <Divider key={`${i}-div`} />}
                    <StyledTooltip
                      title={"Coming soon"}
                      placement="top-start"
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -24],
                              },
                            },
                          ],
                        },
                      }}
                    >
                      <div>
                        <MenuItem
                          disabled
                          key={setting}
                          id={`${setting}-${i}`}
                          onClick={handleCloseUserMenu}
                          sx={{ cursor: "pointer" }}
                        >
                          <Typography>{setting}</Typography>
                        </MenuItem>
                      </div>
                    </StyledTooltip>
                  </div>
                ))}
                {isUserLoggedIn && (
                  <MenuItem
                    key={"logOut"}
                    onClick={handleLogOutStatus}
                    sx={{
                      border: "0.25px solid rgba(0, 0, 0, 0.18)",
                      borderRadius: "6px",
                      justifyContent: "center",
                    }}
                  >
                    <Typography>Logout</Typography>
                  </MenuItem>
                )}
              </StyledMenu>
            </Box>
          ) : (
            <Box
              sx={{
                flexGrow: 0,
                mr: 1,
                ml: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                key={"login"}
                className="view-more-or-less"
                onClick={handleLoginStatus}
                sx={{ width: "100px" }}
              >
                Login
              </Button>
            </Box>
          )}
        </Toolbar>
      </Container>
      <LoginModal
        openModal={openLoginModal}
        handleClose={handleCloseLoginModal}
      />
    </AppBar>
  );
}
