import { TooltipProps, Tooltip, tooltipClasses } from "@mui/material";
import styled from "styled-components";

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "5px",
    boxShadow: "0 4px 10px 0 rgba(181, 220, 246, 0.31)",
    // linear gradient rounder border
    background:
      "linear-gradient(white, white) padding-box,linear-gradient(180deg, #D3131D 0%, #160F92 100%) border-box",
    borderRadius: "0.5em",
    border: "0.25px solid transparent",

    fontFamily: "Poppins SemiBold",
    fontSize: "10px",
    fontStyle: "oblique",
    color: "rgba(35, 15, 139, 1)",

    "& .MuiTooltip-arrow": {
      color: "#f3f9fd",
    },
  },
}));
