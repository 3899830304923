import {
  Card,
  CardActionArea,
  CardContent,
  Container,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import cardbg from "../../../assets/top-deal-card-bg.svg";

import flipkart_logo from "../../../assets/flipkartLogo.svg";
import amazon_logo from "../../../assets/amazon_logo2.svg";
import croma_logo from "../../../assets/croma_logo.svg";
import jiomart_logo from "../../../assets/jiomart-logo.svg";

import "./DealsCard.css";
import { StyledTooltip } from "../StyledComponents/StyledTooltip/StyledTooltip";

type Props = {
  id: string;
  imageUrl: string;
  title: string;
  price: string;
  originalPrice: string;
  retailerLogo: string;
  discount: string;
  hoverText: string;
  hoverPercentage: string;
  productLink: string;
};

const DealsCard: React.FC<Props> = (props) => {
  const overlayRef = useRef<HTMLDivElement>(null); // Create a ref for the overlay div
  const lastHyphenIndex = props?.title.lastIndexOf("-");
  const trimmedTitle = props?.title.substring(0, lastHyphenIndex);

  const handleMouseEnter = () => {
    if (overlayRef.current) {
      overlayRef.current.style.display = "block";
    }
  };

  const handleMouseLeave = () => {
    if (overlayRef.current) {
      overlayRef.current.style.display = "none";
    }
  };

  return (
    <Container className="flex-center container-padding">
      <Card
        id={`top_deal_card_${props?.id}`}
        key={`top_deal_card_key-${props?.id}`}
        variant="outlined"
        style={{ width: "20%", flexGrow: 1 }} //Added fro view more
        sx={{
          backgroundImage: `url(${cardbg})`,

          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          minWidth: "174.7px",

          minHeight: "317px",

          maxWidth: "min-content",
          borderRadius: "50px 50px 48.666px 48.666px",

          border: 0,
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            opacity: 1,
          },
        }}
      >
        {/* Need to comment out CardAction Area while using Link */}
        <CardActionArea
          onClick={() => window.open(props.productLink, "_blank")}
        >
          {/*         <Link
          to={`/product-details/${props?.id}`}
          target="_self"
          style={{ textDecoration: "none" }}
        > */}
          {}
          <CardContent
            sx={{
              p: 2,
              paddingBottom: 0,
              minHeight: "200px",
              position: "relative",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="div-card-media-padding"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <img
                src={props?.imageUrl}
                alt="product"
                style={{
                  width: "50%",
                  height: "70%",
                }}
              />
              <div // Create overlay div for displaying text
                id="overlay" // Set id for overlay div
                ref={overlayRef} // Set ref for overlay div
                style={{
                  position: "absolute",
                  /* width: "100%", */
                  height: "50%",
                  bottom: 0,
                  background: "rgba(255, 255, 255, 0.52)",
                  borderRadius: "0 0 50% 50%",
                  display: "none", // Initially hide overlay
                }}
              >
                <Typography
                  style={{
                    color: "#000",
                    textAlign: "center",
                    marginTop: "10%",
                    fontFamily: "Poppins SemiBold",
                    fontSize: "22px",
                  }}
                >
                  {props?.hoverText} <br />
                  <span style={{ fontSize: "26px" }}>
                    {props?.hoverPercentage} %
                  </span>
                </Typography>
              </div>
            </div>
            <StyledTooltip title={trimmedTitle}>
              <Typography
                gutterBottom
                align="left"
                component="div"
                className="deals-title-styles"
              >
                {trimmedTitle}
              </Typography>
            </StyledTooltip>
            {/*    <br /> */}
            <Typography className="deals-price-styles" align="left">
              Rs. {parseInt(props?.price)}
            </Typography>
            {/*   <br /> */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginLeft: "auto",
              }}
            >
              <Typography className="deals-original-price-styles" align="left">
                Rs. {parseInt(props?.originalPrice)}
              </Typography>
              <IconButton
                aria-label="retailers-logo"
                disableFocusRipple
                sx={{
                  display: "flex",
                  padding: 0,
                  marginLeft: "auto",
                  "&:hover": { backgroundColor: "transparent" },
                }}
              >
                <img
                  loading="lazy"
                  alt="card icon"
                  src={
                    props?.retailerLogo === "FLIPKART"
                      ? flipkart_logo
                      : props?.retailerLogo === "AMAZON"
                      ? amazon_logo
                      : props?.retailerLogo === "CROMA"
                      ? croma_logo
                      : jiomart_logo
                  }
                  className="retailer-logo"
                />
              </IconButton>
            </div>
          </CardContent>
          <Typography className="deals-discount-styles" align="left">
            {props?.discount} % OFF
          </Typography>
        </CardActionArea>
        {/*         </Link>
         */}{" "}
      </Card>
    </Container>
  );
};

export default DealsCard;
