import React from "react";
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Alert,
  CircularProgress,
} from "@mui/material";
import "../Login/LoginModal.css";
import "./ForgotPassword.css";
import { useForm } from "react-hook-form";

import pp_logo from "../../assets/pp-logo-new.svg";
import { StyledModal } from "../common/StyledComponents/StyledModal/StyledModal";
import { CloseRounded } from "@mui/icons-material";
import { StyledButton } from "../common/StyledComponents/StyledButton/StyledButton";
import { StyledTextField } from "../common/StyledComponents/StyledTextField/StyledTextField";
import VerifyCode from "./ChangePassword";
import { serviceVerifyEmail } from "../../api/service/authServices";
import { AxiosError, AxiosResponse } from "axios";

export type Props = {
  openModalForgotPassword: boolean;
  handleCloseForgotPassword: CallableFunction;
};

const ForgotPassword: React.FC<Props> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isErrorMessage, setIsErrorMessage] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [openVerifyCodeModal, setOpenVerifyCodeModal] =
    React.useState<boolean>(false);
  const [verifyEmail, setVerifyEmail] = React.useState<string>("");
  const [loading, setLoading] = React.useState(false); // Add loading state

  const handleClose = () => {
    props.handleCloseForgotPassword(false);
    setIsErrorMessage(false);
  };

  const handleCloseVerifyCode = () => {
    setOpenVerifyCodeModal(false);
    setVerifyEmail("");
  };

  const onSubmit = async (data: any) => {
  
    setVerifyEmail(data.email);
    setErrorMessage("");
    setLoading(true);
    await serviceVerifyEmail(data.email)
      .then((response: AxiosResponse) => {
      
        setLoading(false);
        setOpenVerifyCodeModal(true);
      })
      .catch((error: AxiosError<any>) => {
      
        setLoading(false);
        if (error?.response?.data?.statusCode === 400) {
          setErrorMessage(error.response?.data.error);
        } else {
          setErrorMessage(error?.response?.data.error);
        }
      });
  };

  return (
    <div>
      <StyledModal
        open={props.openModalForgotPassword}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={"login-container"}>
          <Grid container display="flex" flexDirection="column" paddingTop={1}>
            {" "}
            <Grid item xs={12} md={12} lg={12}>
              <div
                className="flex-row"
                style={{ marginBottom: isErrorMessage ? 0 : 20 }}
              >
                <IconButton
                  disableRipple
                  aria-label="price-pinger-logo-label"
                  sx={{
                    padding: 0,
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                  /*     component={Link}
            to="/home" */
                >
                  <img
                    id="price-pinger-logo"
                    alt="Price Pinger Logo"
                    src={pp_logo}
                    height={"31px"}
                    width={"31px"}
                  ></img>
                </IconButton>
                <Typography className="welcome-text" gutterBottom>
                  Forgot Password
                </Typography>
                <IconButton
                  disableRipple
                  aria-label="close modal"
                  sx={{
                    padding: 0,
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                  onClick={handleClose}
                >
                  <CloseRounded />
                </IconButton>
              </div>
            </Grid>
            {errorMessage && (
              <Alert
                severity="error"
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 600,
                  padding: "0px 16px",
                  marginBottom: "10px",
                  backgroundColor: "#FFF",
                  color: "#d32f2f",
                  alignItems: "center",
                }}
              >
                {errorMessage}
              </Alert>
            )}
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                className="subtitle-text"
                gutterBottom
                sx={{ padding: 2 }}
              >
                Please provide a valid email address associated with your
                profile, and we will send you a code using which, you can reset
                your password.
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Box
                component="form"
                noValidate
                sx={{ px: 3 }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <StyledTextField
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Email is Required",
                    },
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please enter a valid email",
                    },
                  })}
                  required
                  margin="dense"
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  size="small"
                  error={Boolean(errors?.email)}
                  helperText={
                    errors?.email ? errors?.email?.message?.toString() : " "
                  }
                  InputLabelProps={{
                    style: { fontSize: "1rem" }, // Change the size of the input label shrink
                  }}
                  //  variant="filled"
                />
                <br />
                <StyledButton
                  type="submit"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={loading ? true : false}
                >
                  {loading ? <CircularProgress size={24} /> : "Send code"}
                </StyledButton>
              </Box>
              <VerifyCode
                email={verifyEmail}
                openModalVerifyCode={openVerifyCodeModal}
                handleCloseVerifyCode={handleCloseVerifyCode}
                handleCloseForgotPassword={handleClose}
              />
            </Grid>
          </Grid>
        </Box>
      </StyledModal>
    </div>
  );
};

export default ForgotPassword;
