import React, { useEffect } from "react";
import "./App.css";
import Header from "./component/header/Header";
import Footer from "./component/footer/Footer";
import { Grid, Stack /*, Typography */ } from "@mui/material";
import CustomScroll from "./component/common/CustomScrollButton/CustomScroll";
import HomePage from "./component/home/HomePage";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import EmbeddedHTMLContent from "./component/EmbeddedHTML/EmbeddedHTMLContent";
import Beta from "./component/common/Beta/Beta";
import PageNotFound from "./component/PageNotFound/PageNotFound";
import AddAffiliateForm from "./component/AddAffilateForm/AddAffiliateForm";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const termsAndConditionsString = localStorage.getItem("termsAndConditions");
  let title = "";
  let content = "";
  if (termsAndConditionsString) {
    const { title: storedTitle, content: storedContent } = JSON.parse(
      termsAndConditionsString
    );
    title = storedTitle;
    content = storedContent;
  }

  // const { title, content } = location.state || {};
  useEffect(() => {
    if (location.pathname === "/") {
      const searchParams = location.search;
      navigate(`/home${searchParams}`);
    }
  }, [navigate, location]);

  useEffect(() => {}, [title, content, location]);

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location.hash]);

  return (
    <div className="App">
      <Stack
        className="stack"
        px={2}
        bgcolor="common.white"
        height={window.innerHeight}
        borderRadius={"9px"}
      >
        {" "}
        <CustomScroll>
          <Grid
            container
            gap={1}
            flexDirection="column"
            paddingTop={1}
            display={"flex"}
            justifyContent={"center"}
            alignContent={"center"}
          >
            <Grid item xs={12} md={12} lg={12} className="beta">
              <Beta />
            </Grid>

            <Grid item xs={12} md={12} lg={12} sx={{ paddingTop: 3 }}>
              <Header />
            </Grid>
            <Grid item xs={12} md={12} lg={12} style={{ width: "100%" }}>
              <Routes>
                <Route path="/home" element={<HomePage />} />
                <Route path="/home/:section" element={<HomePage />} />
                <Route path="/add-affiliate" element={<AddAffiliateForm />} />
                {/*   <Route
                  path="/blogs/:blogName"
                  element={<EmbeddedHTMLContent htmlContentUrl={blogUrl} />}
                /> */}
                <Route
                  path="/blogs/:blogName"
                  element={
                    <EmbeddedHTMLContent title={title} content={content} />
                  }
                />
                <Route
                  path="/:slug"
                  element={
                    <EmbeddedHTMLContent title={title} content={content} />
                  }
                />
                {/*                 <Route
                  path="/product-details/:id"
                  element={<ProductDetails />}
                /> */}
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Footer />
            </Grid>
          </Grid>
        </CustomScroll>
      </Stack>
    </div>
  );
}

export default App;
