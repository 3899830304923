import React from "react";
import { Box, Typography, IconButton, Grid } from "@mui/material";
import "../Login/LoginModal";
import pp_logo from "../../assets/pp-logo-new.svg";
import { StyledModal } from "../common/StyledComponents/StyledModal/StyledModal";
import { CloseRounded } from "@mui/icons-material";

import SignUpForm from "./SignupForm";
import "./SignUpModal.css";

export type Props = {
  setSuccessMessage: CallableFunction;
  openSignUpModal: boolean;
  handleSignUpClose: CallableFunction;
  handleCloseLogin: CallableFunction;
};

const SignUpModal: React.FC<Props> = (props) => {
  const handleSignUpClose = () => {
    props.handleSignUpClose(false);
  };

  const handleCloseLogin = () => {
    props.handleCloseLogin(false);
  };

  return (
    <div>
      <StyledModal
        open={props.openSignUpModal}
        onClose={handleSignUpClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={"sign-up-container"}>
          <Grid container display="flex" flexDirection="column" paddingTop={1}>
            {" "}
            <Grid item xs={12} md={12} lg={12} marginBottom={1}>
              <div className="flex-row">
                <IconButton
                  disableRipple
                  aria-label="price-pinger-logo-label"
                  sx={{
                    padding: 0,
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                  /*     component={Link}
            to="/home" */
                >
                  <img
                    id="price-pinger-logo"
                    alt="Price Pinger Logo"
                    src={pp_logo}
                    height={"31px"}
                  ></img>
                </IconButton>
                <Typography className="welcome-text" gutterBottom>
                  Create an account
                </Typography>
                <IconButton
                  disableRipple
                  aria-label="close modal"
                  sx={{
                    padding: 0,

                    "&:hover": { backgroundColor: "transparent" },
                  }}
                  onClick={handleSignUpClose}
                >
                  <CloseRounded />
                </IconButton>
              </div>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <br />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <SignUpForm
                handleClose={handleSignUpClose}
                setSuccessMessage={props?.setSuccessMessage}
                handleCloseLogin={handleCloseLogin}
              ></SignUpForm>
            </Grid>
          </Grid>
        </Box>
      </StyledModal>
    </div>
  );
};

export default SignUpModal;
