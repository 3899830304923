/* eslint-disable jsx-a11y/anchor-is-valid */
import { VisibilityOff, Visibility } from "@mui/icons-material";
import {
  Box,
  InputAdornment,
  IconButton,
  Alert,
  CircularProgress,
} from "@mui/material";
import React, { useEffect } from "react";
//import { Link } from "react-router-dom";
import { StyledButton } from "../common/StyledComponents/StyledButton/StyledButton";
import { StyledTextField } from "../common/StyledComponents/StyledTextField/StyledTextField";
import { useForm } from "react-hook-form";
import { serviceGetToken } from "../../api/service/authServices";
import { AxiosResponse, AxiosError } from "axios";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import { serviceFetchUserDetails } from "../../api/service/services";

type Props = {
  handleClose: CallableFunction;
  isErrorMessage: CallableFunction;
  resetForm: boolean;
};

const LoginForm: React.FC<Props> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [showPassword, setShowPassword] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [openForgotPasswordModal, setOpenForgotPasswordModal] =
    React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(false); // Add loading state

  useEffect(() => {
    if (props?.resetForm) {
      setErrorMessage("");
      reset();
    }
  }, [props?.resetForm, reset]);

  const handleForgotPasswordClick = () => {
    setOpenForgotPasswordModal(true);
  };

  const handleForgotPasswordClose = () => {
    setOpenForgotPasswordModal(false);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") {
      const activeElement = document.activeElement as HTMLElement;
      if (activeElement.tagName === "A") {
        event.preventDefault();
      }
    }
  };

  const onSubmit = async (data: any) => {
    setErrorMessage("");
    setLoading(true); // Set loading to true when form is submitted

    await serviceGetToken(data)
      .then(async (response: AxiosResponse) => {
        localStorage.setItem("email", response?.data?.data?.email);
        localStorage.setItem("displayName", response?.data?.data?.displayName);
        const loginDetails = {
          token: response?.data?.data?.token,
          email: response?.data?.data?.email,
          displayName: response?.data?.data?.displayName,
        };
        localStorage.setItem("loginDetails", JSON.stringify(loginDetails));
        setLoading(false); // Set loading to false after request is completed

        props.handleClose(true);
        await serviceFetchUserDetails(response?.data?.data?.email)
          .then((response: AxiosResponse) => {
            localStorage.setItem("userId", response?.data?.user_uuid);
          })
          .catch((error: AxiosError<any>) => {
            setLoading(false); // Set loading to false after request is completed

            console.log("Error:", error.response?.data);
          });
      })
      .catch((error: AxiosError<any>) => {
        setLoading(false); // Set loading to false after request is completed

        console.log("Error:", error.response?.data);
        props.isErrorMessage(true);
        if (error?.response?.data?.statusCode === 403) {
          setErrorMessage("Invalid email or password");
        } else {
          setErrorMessage("Server Error!");
        }
      });
  };

  return (
    <div>
      <Box
        component="form"
        noValidate
        sx={{ px: 3 }}
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={handleKeyDown}
      >
        {errorMessage && (
          <Alert
            severity="error"
            sx={{
              fontFamily: "Poppins",
              fontWeight: 600,
              padding: "0",
              marginBottom: "10px",
              backgroundColor: "#FFF",
              color: "#d32f2f",
              alignItems: "center",
            }}
          >
            {errorMessage}
          </Alert>
        )}
        <StyledTextField
          {...register("email", {
            required: {
              value: true,
              message: "Email is Required",
            },
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Please enter a valid email",
            },
          })}
          required
          margin="dense"
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          size="small"
          error={Boolean(errors?.email)}
          helperText={errors?.email ? errors?.email?.message?.toString() : " "}
          InputLabelProps={{
            style: { fontSize: "1rem" }, // Change the size of the input label shrink
          }}
          //  variant="filled"
        />
        <StyledTextField
          {...register("password", { required: "Password is required" })}
          margin="dense"
          required
          fullWidth
          name="password"
          label="Password"
          id="password"
          autoComplete="current-password"
          size="small"
          type={showPassword ? "text" : "password"}
          error={Boolean(errors?.password)}
          helperText={
            errors?.password ? errors?.password?.message?.toString() : " "
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            style: { fontSize: "1rem" }, // Change the size of the input label shrink
          }}
          // variant="filled"
        />
        {/*         <Link
          component="button"
          sx={{ float: "right", fontSize: "12px" }}
          onClick={() => handleForgotPasswordClick()}
          tabIndex={-1}
        >
          Forgot password?
        </Link> */}
        <span
          style={{
            float: "right",
            fontSize: "12px",
            cursor: "pointer",
            color: "blue",
            textDecoration: "underline",
          }}
          onClick={handleForgotPasswordClick}
        >
          Forgot password?
        </span>
        <StyledButton
          type="submit"
          sx={{ mt: 1, mb: 1 }}
          disabled={loading ? true : false}
        >
          {loading ? <CircularProgress size={24} /> : "Log in"}
        </StyledButton>
        <ForgotPassword
          openModalForgotPassword={openForgotPasswordModal}
          handleCloseForgotPassword={handleForgotPasswordClose}
        />
      </Box>
    </div>
  );
};

export default LoginForm;
