import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import BannerCarousel from "../BannerCarousel/BannerCarousel";
import DealPagination from "../ViewMore/DealPagination";
import BlogCarousel from "../BlogCarousel/BlogCarousel";
import { useLocation } from "react-router-dom";
import { serviceFetchUserDetails } from "../../api/service/services";
import { AxiosError, AxiosResponse } from "axios";

const HomePage = () => {
  const location = useLocation();

  const urlParams =
    new URLSearchParams(window.location.search) || location.search;
  const status = urlParams.get("status") || "";
  const email = urlParams.get("email") || "";
  const token = urlParams.get("token") || "";
  const name = urlParams.get("name") || "";

  useEffect(() => {
    if (status === "success") {
      if (token !== "" && email !== "" && name !== "") {
        localStorage.setItem("token", token);
        localStorage.setItem("email", email);
        localStorage.setItem("displayName", name);
        serviceFetchUserDetails(email)
          .then((response: AxiosResponse) => {
            localStorage.setItem("userId", response?.data?.user_uuid);
          })
          .catch((error: AxiosError<any>) => {
            console.log("Error:", error.response?.data);
          });
      }
      window.location.href = "/";
    }
  }, [email, name, status, token]);

  return (
    <div>
      <Grid
        container
        gap={3}
        display="flex"
        flexDirection="column"
        justifyContent={"center"}
        alignContent={"center"}
        paddingTop={1}
      >
        <Grid item xs={12} md={12} lg={12}>
          <BannerCarousel />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <DealPagination />
        </Grid>
        <Grid item xs={12}>
          <Typography id="blogs-section" align="left" className="title-styles">
            Blogs
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <BlogCarousel />
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </div>
  );
};

export default HomePage;
