import React from "react";
import "./BlogCard.css";
import { Card, CardActionArea, Container } from "@mui/material";
import { StyledTooltip } from "../StyledComponents/StyledTooltip/StyledTooltip";

import { useNavigate } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import { serviceFetchBlogsUrlPath } from "../../../api/service/services";

type Props = {
  id: string;
  cardBG: string;
  title: string;
  blogName: string;
  blogUrl: string;
};

const BlogCard: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const handleCardClick = async () => {
    await serviceFetchBlogsUrlPath(props.id)
      .then((response: AxiosResponse) => {
        const blogData = response.data;
        navigate(`/blogs/${props.blogName}`, {
          state: {
            title: blogData.title.rendered,
            content: blogData.content.rendered,
          },
        });
      })
      .catch((error: AxiosError<any>) => {
        console.error("Error fetching blog data:", error);
      });
  };

  return (
    <Container maxWidth={"xs"}>
      <Card
        id={`blog-${props?.id}`}
        variant="outlined"
        sx={{
          minWidth: "188px",
          maxWidth: "188px",
          maxHeight: "148px",
          minHeight: "148px",
          borderRadius: "10px",
          backgroundImage: `url(${props?.cardBG})`,
          backgroundRepeat: "round",
          width: "100%",
          height: "100%",
          border: 0,
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255)",
          },
        }}
        onClick={handleCardClick}
      >
        <CardActionArea>
          <StyledTooltip title={props?.title} placement="top">
            <div className="blog-title-style">{props?.title}</div>
          </StyledTooltip>
        </CardActionArea>
      </Card>
    </Container>
  );
};

export default BlogCard;
