export const configs = {
  get_token_api_endpoint: "/token",
  validate_token_api_endpoint: "/token/validate",
  logout_api_endpoint: "/logout/",
  signup_api_endpoint: "/signup/",
  verify_email_api_endpoint: "/verify-email/",
  change_password_api_endpoint: "/change-password/",
  user_details_api_endpoint: "/user/",

  top_deals_api_endpoint: "/products",
  banners_api_endpoint:
    "/banners?acf_format=standard&_fields=id,title.rendered,acf.banner_image,acf.banner_url,acf.banner_alt",
  blogs_api_endpoint: "/posts?_fields=id,title.rendered,link",
  blog_posts_api_endpoint: "/posts/",
  blog_content_api_endpoint: "?_fields=title,content",
  pages_api_endpoint: "/pages/",
  affliate_params: "pricepinger07-21",
  grap_the_plugin_url:
    "https://chromewebstore.google.com/detail/price-pinger-plugin/plhjnaoodjmclojgcmlgnaojkpddggic",
};
