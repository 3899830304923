import { axiosInstance } from "../axiosInstance";
import { configs } from "../config";

export const serviceFetchProductDetails = (limit: number, offset: number) => {
  let url_path =
    "http://ec2-15-206-106-52.ap-south-1.compute.amazonaws.com/cms/wp-json/wp/v2";
  const endpoint =
    configs.top_deals_api_endpoint + "?limit=" + limit + "&offset=" + offset;

  if (process.env.REACT_APP_API_URL) {
    url_path = process.env.REACT_APP_API_URL;
  }
  return axiosInstance.get(url_path + endpoint);
};

export const serviceFetchBanners = () => {
  let url_path =
    "http://ec2-15-206-106-52.ap-south-1.compute.amazonaws.com/cms/wp-json/wp/v2";
  const endpoint = configs.banners_api_endpoint;

  if (process.env.REACT_APP_API_URL) {
    url_path = process.env.REACT_APP_API_URL;
  }
  return axiosInstance.get(url_path + endpoint);
};

export const serviceFetchBlogs = () => {
  let url_path =
    "http://ec2-15-206-106-52.ap-south-1.compute.amazonaws.com/cms/wp-json/wp/v2";
  const endpoint = configs.blogs_api_endpoint;

  if (process.env.REACT_APP_API_URL) {
    url_path = process.env.REACT_APP_API_URL;
  }
  return axiosInstance.get(url_path + endpoint);
};

export const serviceFetchBlogsUrlPath = (id: any) => {
  let url_path =
    "http://ec2-15-206-106-52.ap-south-1.compute.amazonaws.com/cms/wp-json/wp/v2";

  if (process.env.REACT_APP_API_URL) {
    url_path = process.env.REACT_APP_API_URL;
  }
  const path =
    url_path +
    configs.blog_posts_api_endpoint +
    id +
    configs.blog_content_api_endpoint;

  return axiosInstance.get(path);
};

export const serviceFetchPages = () => {
  let url_path =
    "http://ec2-15-206-106-52.ap-south-1.compute.amazonaws.com/cms/wp-json/wp/v2";
  const endpoint = configs.pages_api_endpoint;

  if (process.env.REACT_APP_API_URL) {
    url_path = process.env.REACT_APP_API_URL;
  }
  return axiosInstance.get(url_path + endpoint);
};

export const serviceFetchUserDetails = (user_email_id: string) => {
  let url_path =
    "http://ec2-15-206-106-52.ap-south-1.compute.amazonaws.com/cms/wp-json/wp/v2";
  const endpoint = configs.user_details_api_endpoint + user_email_id;

  if (process.env.REACT_APP_API_URL) {
    url_path = process.env.REACT_APP_API_URL;
  }
  return axiosInstance.get(url_path + endpoint);
};
