import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import next from "../../assets/nextIcon.svg";
import prev from "../../assets/previousIcon.svg";
import { Container, IconButton, Skeleton } from "@mui/material";
import "./BlogCarousel.css";
import BlogCard from "../common/BlogCard/BlogCard";
import { AxiosResponse, AxiosError } from "axios";
import { serviceFetchBlogs } from "../../api/service/services";
import blog_image from "../../assets/mobile-phone-supports.png";

const BlogCarousel: React.FC = () => {
  const [blogData, setBlogData] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    serviceFetchBlogs()
      .then((response: AxiosResponse) => {
        setBlogData(response?.data);
      })
      .catch((error: AxiosError) => {
        console.log("Error:", error.message);
      });
  }, []);

  useEffect(() => {}, [blogData]);

  const CustomPrevArrow: React.FC = (props) => (
    <div {...props} className="custom-prev-arrow">
      <IconButton
        disableRipple
        aria-label="previous slidder "
        sx={{
          "&:hover": { backgroundColor: "transparent" },
        }}
      >
        <img
          id="previous-icon"
          alt="previous icon"
          src={prev}
          className="arrow-styles"
        />
      </IconButton>
    </div>
  );

  const CustomNextArrow: React.FC = (props) => (
    <div {...props} className="custom-next-arrow">
      <IconButton
        disableRipple
        aria-label="next slider"
        sx={{
          "&:hover": { backgroundColor: "transparent" },
        }}
      >
        <img
          id="next-icon"
          alt="next icon"
          src={next}
          className="arrow-styles"
        />
      </IconButton>
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: blogData.length >= 5 ? 5 : blogData.length,
    slidesToScroll: blogData.length >= 5 ? 2 : blogData.length,
    prevArrow: currentSlide === 0 ? <></> : <CustomPrevArrow />,
    nextArrow:
      currentSlide === blogData.length - 5 ? <></> : <CustomNextArrow />,
    beforeChange: (oldIndex: number, newIndex: number) => {
      setCurrentSlide(newIndex);
    },
    responsive: [
      {
        breakpoint: 1536, // xl
        settings: {
          slidesToShow: blogData.length >= 5 ? 5 : blogData.length,
          slidesToScroll: 2,

          infinite: true,
        },
      },
      {
        breakpoint: 1200, // lg
        settings: {
          slidesToShow: blogData.length >= 5 ? 3 : blogData.length,
          slidesToScroll: 2,

          infinite: true,
        },
      },
      {
        breakpoint: 900, // md
        settings: {
          slidesToShow: blogData.length >= 5 ? 3 : blogData.length,
          slidesToScroll: 2,

          dots: true,
        },
      },
      {
        breakpoint: 600, // sm
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480, // xs
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <>
      <Container
        maxWidth={blogData.length <= 2 ? "sm" : "xl"}
        /*   style={{ marginLeft: 0 }} */
        className="container"
      >
        {" "}
        {blogData.length === 0 && (
          <Skeleton
            variant="rounded"
            width="100%"
            height={300}
            animation="wave"
          />
        )}
        <Slider {...settings} key={`blog-slider-${Math.random()}`}>
          {blogData.map((blog: any, index) => (
            <div key={index} style={{ width: "auto" }}>
              <BlogCard
                id={blog.id}
                cardBG={blog_image}
                //  cardBG={blog.blog_image_path}
                title={blog.title.rendered}
                blogName={blog.title.rendered}
                blogUrl={blog.id} //  htmlFilePath="/blog.html"
              />
            </div>
          ))}
        </Slider>
      </Container>
    </>
  );
};

export default BlogCarousel;
