/* eslint-disable react-hooks/exhaustive-deps */
import { Box, IconButton } from "@mui/material";
import scrollDown from "../../../assets/scrollDowngrade.svg";
import scrollUp from "../../../assets/scrollUpgrade.svg";
import "./CustomScrollButton.css";
import React, { useEffect, useRef, useState } from "react";
import { StyledTooltip } from "../StyledComponents/StyledTooltip/StyledTooltip";

type CustomScrollProps = {
  children: React.ReactNode;
};

const CustomScroll: React.FC<CustomScrollProps> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showUpArrow, setShowUpArrow] = useState(false);
  const [showDownArrow, setShowDownArrow] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const scrollTop = containerRef.current.scrollTop;
        const scrollHeight = containerRef.current.scrollHeight;
        const clientHeight = containerRef.current.clientHeight;
        const threshold = 20; // Adjust the threshold value as needed

        // Set hasScrolled to true if the user scrolls down from the top
        if (scrollTop > 0) {
          setHasScrolled(true);
        }

        // Display scroll-to-top button only when it reaches the bottom of the page
        setShowUpArrow(
          scrollTop > threshold &&
            scrollTop + clientHeight + threshold >= scrollHeight
        );

        // Display scroll-down button only when not at the bottom of the page and if the user has scrolled
        setShowDownArrow(
          hasScrolled && scrollTop + clientHeight + threshold < scrollHeight
        );
      }
    };

    const handleResize = () => {
      if (containerRef.current) {
        const scrollHeight = containerRef.current.scrollHeight;
        const clientHeight = containerRef.current.clientHeight;

        // Display scroll buttons only if content is larger than the container
        const shouldShowButtons = scrollHeight > clientHeight;
        setShowUpArrow(false); // Initially hide the scroll-up button
        setShowDownArrow(shouldShowButtons && hasScrolled);
      }
    };

    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleResize);
      handleScroll(); // Initial check
      // handleResize(); // Initial check
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleResize);
      }
    };
  }, [hasScrolled]);

  const scrollTo = (position: number) => {
    if (containerRef.current) {
      const start = containerRef.current.scrollTop;
      const change = position - start;
      const duration = 500; // Adjust the duration as needed
      const startTime = performance.now();

      const animateScroll = (currentTime: number) => {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);
        const easeProgress = 0.5 - 0.5 * Math.cos(progress * Math.PI);

        if (containerRef.current) {
          containerRef.current.scrollTop = start + change * easeProgress;
        }

        if (progress < 1 && containerRef.current) {
          window.requestAnimationFrame(animateScroll);
        }
      };

      window.requestAnimationFrame(animateScroll);
    }
  };
  /* 
  const handleScrollUp = () => {
    if (containerRef.current) {
      // Check if already at the top
      if (containerRef.current.scrollTop > 0) {
        const scrollAmount = -window.innerHeight;
        scrollTo(containerRef.current.scrollTop + scrollAmount);
      }
    }
  }; */

  const handleScrollDown = () => {
    if (containerRef.current) {
      const scrollAmount = window.innerHeight;
      scrollTo(containerRef.current.scrollTop + scrollAmount);
    }
  };

  const handleScrollToTop = () => {
    if (containerRef.current) {
      scrollTo(0); // Scroll to the top
    }
  };

  return (
    <>
      <Box
        className="my-box"
        marginTop={"10px"}
        marginBottom={"20px"}
        ref={containerRef}
        overflow={"auto"}
      >
        {children}
      </Box>

      {showUpArrow && (
        <div className="scroll-arrow up" onClick={handleScrollToTop}>
          <StyledTooltip title="Scroll to Top" placement="right-end">
            <IconButton
              aria-label="scroll to top"
              sx={{
                "&:hover": { backgroundColor: "transparent" },
              }}
            >
              <img
                id="scrollButton"
                alt="scroll to top"
                src={scrollUp}
                className="arrow-styles arrow-vibrate"
              />
            </IconButton>
          </StyledTooltip>
        </div>
      )}

      {showDownArrow && (
        <div className="scroll-arrow down" onClick={handleScrollDown}>
          <StyledTooltip title="Show more" placement="right-end">
            <IconButton
              aria-label="custom scroll"
              sx={{
                "&:hover": { backgroundColor: "transparent" },
              }}
            >
              <img
                id="scrollButton"
                alt="scroll Down"
                src={scrollDown}
                className="arrow-styles arrow-vibrate"
              />
            </IconButton>
          </StyledTooltip>
        </div>
      )}
    </>
  );
};

export default CustomScroll;
