// BannerCarousel.tsx
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import next from "../../assets/nextIcon.svg";
import prev from "../../assets/previousIcon.svg";
import { Container, IconButton, Skeleton } from "@mui/material";
import "./BannerCarousel.css";
import { serviceFetchBanners } from "../../api/service/services";
import { AxiosResponse } from "axios";

const BannerCarousel: React.FC = () => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [bannerData, setBannerData] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    serviceFetchBanners().then((response: AxiosResponse) => {
      setBannerData(response?.data);
    });
  }, []);

  useEffect(() => {}, [bannerData]);

  const handleImagesLoad = () => {
    setImagesLoaded(true);
  };

  const CustomPrevArrow: React.FC = (props) => (
    <div {...props} className="custom-prev-arrow">
      <IconButton
        disableRipple
        aria-label="previous slidder "
        sx={{
          "&:hover": { backgroundColor: "transparent" },
        }}
      >
        <img
          id="previous-icon"
          alt="previous icon"
          src={prev}
          className="arrow-styles"
        />
      </IconButton>
    </div>
  );

  const CustomNextArrow: React.FC = (props) => (
    <div {...props} className="custom-next-arrow">
      <IconButton
        disableRipple
        aria-label="next slider"
        sx={{
          "&:hover": { backgroundColor: "transparent" },
        }}
      >
        <img
          id="next-icon"
          alt="next icon"
          src={next}
          className="arrow-styles"
        />
      </IconButton>
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: bannerData.length >= 2 ? 2 : bannerData.length,
    slidesToScroll: bannerData.length >= 2 ? 1 : bannerData.length,
    prevArrow: currentSlide === 0 ? <></> : <CustomPrevArrow />,
    nextArrow:
      currentSlide === bannerData.length - 2 ? <></> : <CustomNextArrow />,
    beforeChange: (oldIndex: number, newIndex: number) => {
      setCurrentSlide(newIndex);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <Container
      maxWidth={"xl"}
      /*   maxWidth={false}
      style={{ maxWidth: "100%" }} */
      /* style={{ marginLeft: 0 }} */ className="container"
    >
      {!imagesLoaded && (
        <Skeleton variant="rounded" height={300} animation="wave" />
      )}
      <Slider {...settings}>
        {bannerData.map((banner: any, index) => (
          <div key={index} className="slider-image-container">
            <img
              id={`banner-${banner.id}`}
              src={banner.acf.banner_image}
              alt={banner.acf.banner_alt || `Banner ${index + 1}`}
              className={`slider-image`}
              onClick={() => window.open(banner.acf.banner_url, "_blank")}
              onLoad={handleImagesLoad}
              style={{
                borderRadius: "20px",
              }}
            />
          </div>
        ))}
      </Slider>
    </Container>
  );
};

export default BannerCarousel;
