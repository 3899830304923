import axios from "axios";
import { axiosInstance } from "../axiosInstance";
import { configs } from "../config";

export const serviceGetToken = (user_data: any) => {
  let url_path =
    "http://ec2-15-206-106-52.ap-south-1.compute.amazonaws.com/cms/wp-json/jwt-auth/v1";
  const endpoint = configs.get_token_api_endpoint;

  if (process.env.REACT_APP_AUTH_URL) {
    url_path = process.env.REACT_APP_AUTH_URL;
  }
 
  const path = url_path + endpoint;
  const data = {
    username: user_data.email,
    password: user_data.password,
  };
 
  return axios.post(path, data);
};

export const serviceValidateToken = (token: string) => {
  let url_path =
    "http://ec2-15-206-106-52.ap-south-1.compute.amazonaws.com/cms/wp-json/jwt-auth/v1";
  const endpoint = configs.validate_token_api_endpoint;

  if (process.env.REACT_APP_AUTH_URL) {
    url_path = process.env.REACT_APP_AUTH_URL;
  }

  const path = url_path + endpoint;

  return axios.post(
    path,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const serviceLogout = (email: string) => {
  let url_path =
    "http://ec2-15-206-106-52.ap-south-1.compute.amazonaws.com/cms/wp-json/wp/v2";
  const endpoint = configs.logout_api_endpoint;

  if (process.env.REACT_APP_API_URL) {
    url_path = process.env.REACT_APP_API_URL;
  }

  const path = url_path + endpoint;
  const data = {
    email: email,
  };

  return axiosInstance.post(path, JSON.stringify(data));
};

export const serviceSignUp = (user_data: any) => {
  let url_path =
    "http://ec2-15-206-106-52.ap-south-1.compute.amazonaws.com/cms/wp-json/wp/v2";
  const endpoint = configs.signup_api_endpoint;

  if (process.env.REACT_APP_API_URL) {
    url_path = process.env.REACT_APP_API_URL;
  }

  const path = url_path + endpoint;
  const data = {
    name: user_data.name,
    email: user_data.email,
    password: user_data.password,
  };

  return axiosInstance.post(path, JSON.stringify(data)); 
};

export const serviceVerifyEmail = (email: string) => {
  let url_path =
    "http://ec2-15-206-106-52.ap-south-1.compute.amazonaws.com/cms/wp-json/wp/v2";
  const endpoint = configs.verify_email_api_endpoint;

  if (process.env.REACT_APP_API_URL) {
    url_path = process.env.REACT_APP_API_URL;
  }

  const path = url_path + endpoint;
  const data = {
    email: email,
  };

  return axios.post(path, JSON.stringify(data), {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const serviceChangePassword = (info: any) => {
  let url_path =
    "http://ec2-15-206-106-52.ap-south-1.compute.amazonaws.com/cms/wp-json/wp/v2";
  const endpoint = configs.change_password_api_endpoint;

  if (process.env.REACT_APP_API_URL) {
    url_path = process.env.REACT_APP_API_URL;
  }

  const path = url_path + endpoint;
  const data = {
    email: info.email,

    password: info.password,
    otp: info.otp,
  };

  return axios.post(path, JSON.stringify(data), {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
