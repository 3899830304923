import { Button } from "@mui/material";
import React from "react";
import "./LoginModal.css";
const ulContainerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-evenly",
};

const SocialLoginContainer = () => {
  const handleLoginClick = (provider: string) => {
    switch (provider) {
      case "google":
        (window as any).theChampInitiateLogin(
          document.getElementById("theChampGoogleButton"),
          "google"
        );
        break;
      /*  case "facebook":
        (window as any).theChampInitiateLogin(
          document.getElementById("theChampFacebookButton"),
          "facebook"
        );
        break;
      case "instagram":
        (window as any).theChampInitiateLogin(
          document.getElementById("theChampInstagramButton"),
          "instagram"
        );
        break; */
      default:
        break;
    }
  };

  return (
    <div className="the_champ_outer_login_container">
      <div className="the_champ_login_container">
        <ul className="the_champ_login_ul" style={ulContainerStyle}>
          <li>
            <Button
              variant="outlined"
              className="social-login"
              sx={{
                fontFamily: "Poppins !important",
                "&:hover": {
                  background:
                    "linear-gradient(white, white) padding-box padding-box,linear-gradient(rgb(211, 19, 29) 0%, rgb(22, 15, 146) 100%) border-box border-box",
                  borderRadius: "0.5em",
                  border: "0.25px solid transparent",
                },
              }}
              onClick={() => handleLoginClick("google")}
            >
              <i
                id="theChampGoogleButton"
                className="theChampLogin theChampGoogleBackground theChampGoogleLogin"
                title="Login with Google"
                style={{
                  display: "block",
                  borderRadius: "8px",
                  border: 0,
                  height: "30px",
                  width: "30px",
                }}
                onClick={() => handleLoginClick("google")}
              >
                <svg
                  style={{ display: "block" }}
                  className="theChampLoginSvg theChampGoogleLoginSvg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                />
              </i>
              Login using Google
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SocialLoginContainer;
