// Footer.tsx
import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Link,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import bgImage from "../../assets/footerBackground.svg";
import "./Footer.css";
import { serviceFetchPages } from "../../api/service/services";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosResponse, AxiosError } from "axios";

const footerLinks = [
  { label: "About Us", href: "#about-us" },
  { label: "Contact Us", href: "#contact-us" },
  { label: "Disclaimers", href: "#disclaimers" },
  { label: "Privacy Policy", href: "#privacy-policy" },
  { label: "Terms & Conditions", href: "#terms-conditions" },
];

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const [pagesData, setPagesData] = useState<any>([]);
  const location = useLocation();
  const [label, setLabel] = useState<string>("");
  const path = location.pathname;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const footerStyle: React.CSSProperties = {
    backgroundImage: `url(${bgImage})`, // Replace with the path to your image
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    color: "#fff", // Replace with your preferred text color
    padding: "20px 0px 0px 20px",
    marginTop: "auto",
    height: "auto",
  };

  const linkStyle: React.CSSProperties = {
    marginRight: "16px",
    color: "#fff", // Replace with your preferred link color
    textDecoration: "none",
    fontFamily: "Poppins",
    fontWeight: 500,
    lineHeight: "44px",
    letterSpacing: "0em",
    textAlign: "left",
    display: "inline-block",
    whiteSpace: isSmallScreen ? "nowrap" : "normal",
    fontSize: isSmallScreen ? "12px" : "18px",
  };

  const containerStyle: React.CSSProperties = {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    maxWidth: "100%",
  };

  const hoverLinkStyle: React.CSSProperties = {
    ...linkStyle,
    borderBottom: "2px solid #fff", // Replace with your preferred underline color
  };

  const fetchData = async () => {
    await serviceFetchPages()
      .then((response: AxiosResponse) => {
        setPagesData(response.data);
      })
      .catch((error: AxiosError<any>) => {
        console.error("Error fetching pages:", error);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {}, [pagesData]);

  useEffect(() => {
    if (
      path === "/privacy-policy" ||
      path === "/terms-and-conditions" ||
      path === "/contact-us"
    ) {
      if (path === "/privacy-policy") setLabel("Privacy Policy");
      if (path === "/terms-and-conditions") setLabel("Terms & Conditions");
      if (path === "/contact-us") setLabel("Contact Us");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  useEffect(() => {
    if (label === "Privacy Policy") {
      const privacyPolicy = pagesData.find((page: any) =>
        /privacy-policy/i.test(page.slug)
      );

      if (privacyPolicy) {
        navigate(`/${privacyPolicy?.slug}`, {
          state: {
            title: privacyPolicy?.title?.rendered,
            content: privacyPolicy?.content?.rendered,
          },
        });
      }
    } else if (label === "Terms & Conditions") {
      const termsAndConditions = pagesData.find((page: any) =>
        /terms-and-conditions/i.test(page.slug)
      );
      if (termsAndConditions) {
        navigate(`/${termsAndConditions?.slug}`, {
          state: {
            title: termsAndConditions?.title?.rendered,
            content: termsAndConditions?.content?.rendered,
          },
        });
      }
    } else if (label === "Contact Us") {
      const contactUs = pagesData.find((page: any) =>
        /contact-us/i.test(page.slug)
      );
      if (contactUs) {
        navigate(`/${contactUs?.slug}`, {
          state: {
            title: contactUs?.title?.rendered,
            content: contactUs?.content?.rendered,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label, pagesData]);

  const handleLinkClick = (labelname: string) => {
    setLabel(labelname);
  };

  return (
    <footer style={footerStyle}>
      <Container maxWidth={false} style={containerStyle}>
        <Typography variant={isSmallScreen ? "body1" : "h6"} align="center">
          {footerLinks.map((link, index) => (
            <Link
              key={index}
              style={linkStyle}
              onClick={(event) => {
                event.preventDefault();
                handleLinkClick(link.label);
              }}
              sx={{
                cursor: "pointer",
                "&:hover": hoverLinkStyle,
              }}
            >
              {link.label}
            </Link>
          ))}
        </Typography>

        <Typography align="center" className="footer-styles">
          Copyright ©2024 PricePinger, Inc. All rights reserved. Privacy & Legal
          Policies
        </Typography>
      </Container>
    </footer>
  );
};

export default Footer;
