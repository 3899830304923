import { Modal, styled } from "@mui/material";

export const StyledModal = styled(Modal)({
  "& .MuiBackdrop-root": {
    background: "rgba(0, 0, 0, 45%)",
  },
  "& .MuiDivider-root": {
    left: "35%",
    top: "20%",
    height: " 345px",
    position: "absolute",
    opacity: 1,
    zIndex: 12,
    border: "solid 1px #e8e8e8",
  },
});
