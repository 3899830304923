import axios, { AxiosInstance, AxiosResponse } from "axios";
import {
  serviceGetToken,
  serviceLogout,
  serviceValidateToken,
} from "./service/authServices";

const BASE_URL = process.env.REACT_APP_API_URL;

// Function to get the JWT token
async function getToken() {
  const username = process.env.REACT_APP_USERNAME || "";
  const password = process.env.REACT_APP_PASSWORD || "";
  const data = { email: username, password: password };
  try {
    const res = await serviceGetToken(data);
    const token = res?.data?.data?.token;
    const email = res?.data?.data?.email;
    window.localStorage.setItem("email-test", email);
    window.localStorage.setItem("token-test", token);
    return token;
  } catch (error: any) {
    alert(`Error: ${error.message}`);
    console.log(error);
    return ""; // Forcing non-null value for now but should be handled properly in future releases
  }
}

// Creating a customized Axios instance with custom configurations
export const axiosInstance: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Axios interceptor for adding token to request headers
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await getToken();

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    } else {
      delete config.headers.common["Authorization"];
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Axios interceptor for handling session expiration
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const token = localStorage.getItem("token");

    if (error.response.status === 403) {
      if (token) {
        await serviceValidateToken(token).then((res: AxiosResponse) => {
          if (res?.data?.statusCode === 200) {
            // make the api request

            axiosInstance.defaults.headers.common["Authorization"] =
              "Bearer " + token;
          } else {
            alert("Session Expire.. Please login again");
          }
        });
      } else {
        // If token is expired or not available, login again
        alert("Session expires!!!!. Please login again");
      }
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      // Perform token refresh or logout
      // For simplicity, let's assume logout
      logout();
      window.location.reload(); // Reload the app or redirect to login
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

// Function to log out user
export const logout = async () => {
  const email = localStorage.getItem("email") || "";
  await serviceLogout(email)
    .then((res: AxiosResponse) => {
      window.localStorage.removeItem("email");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("displayName");
      window.localStorage.removeItem("userId");
      window.localStorage.removeItem("loginDetails");
      window.localStorage.removeItem("termsAndConditions");

      getToken();
      window.location.href = "/";
    })
    .catch((err) => {
      console.log(err);
    });
};

// Function to handle logout when window/tab is closed
export const handleLogoutOnClose = () => {
  window.addEventListener("beforeunload", () => {
    logout();
  });
};
