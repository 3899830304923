import React from "react";
import "./Beta.css";
//import { Typography } from "@mui/material";

const Beta = () => {
  //  return <div className="beta beta-2">PricePinger - Beta Website </div>;
  return (
    /*  <Typography id="top-deals-section" align="left" className="beta">
      PricePinger - Beta Website
    </Typography> */
    <div> PricePinger - Beta Website</div>
  );
};

export default Beta;
