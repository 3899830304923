/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  Button,
  Typography,
  useMediaQuery,
  createTheme,
  Skeleton,
  CircularProgress,
} from "@mui/material";

import { createBreakpoints } from "@mui/system";

import { serviceFetchProductDetails } from "../../api/service/services";
import { AxiosError, AxiosResponse } from "axios";
import DealsCard from "../common/DealsCard/DealsCard";
import "./ViewMore.css";

const DealPagination = () => {
  const [cards, setCards] = useState<any>([]);
  const [rows, setRows] = useState(2); // Set initial rows to 2
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // Track loading state

  const prevOffset = useRef(offset);

  const customBreakpoints = createBreakpoints({
    values: {
      xs: 460,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  });

  const theme = createTheme({
    breakpoints: customBreakpoints,
  });
  const isXsScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLgScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isXlScreen = useMediaQuery(theme.breakpoints.down("xl"));

  let cardsPerRow = 5;
  if (isXsScreen) {
    cardsPerRow = 1;
  } else if (isSmScreen) {
    cardsPerRow = 2;
  } else if (isMdScreen) {
    cardsPerRow = 3;
  } else if (isLgScreen) {
    cardsPerRow = 4;
  } else if (isXlScreen) {
    cardsPerRow = 5; // or any other value you want for large and xl screens
  }

  useEffect(() => {
    const initialLimit = cardsPerRow * 2;
    const initialOffset = 0;
    fetchProductDetails(initialLimit, initialOffset);
  }, []);

  useEffect(() => {
    if (offset !== prevOffset.current) {
      fetchProductDetails(limit, offset);
      prevOffset.current = offset; // Update the previous offset
    }
  }, [offset]);

  useEffect(() => {}, [cards]);

  const fetchProductDetails = (limit: number, offset: number) => {
    setIsLoading(true); // Set loading state to true before fetching data
    serviceFetchProductDetails(limit, offset)
      .then((response: AxiosResponse) => {
        setCards((prevCards: any) => [...prevCards, ...response?.data?.data]);

        setTotalProducts(parseInt(response?.data?.total_items) || 10);
      })
      .catch((error: AxiosError) => {
        console.log("Error:", error.message);
      })
      .finally(() => {
        setIsLoading(false); // Set loading state to false after data is fetched
      });
  };

  const handleViewMore = () => {
    setRows((prevRows) => prevRows + 2);
    setLimit(2 * cardsPerRow);
    setOffset((prevOffset) => prevOffset + 2 * cardsPerRow);
  };

  return (
    <div>
      <Accordion defaultExpanded style={{ boxShadow: "none" }}>
        <Typography
          id="top-deals-section"
          align="left"
          className="title-styles"
          style={{ padding: "0px 30px" }}
        >
          Top deals
        </Typography>
        <AccordionDetails>
          {cards.length === 0 && (
            <Skeleton
              variant="rounded"
              width="100%"
              height={300}
              animation="wave"
            />
          )}
          {Array.from({ length: rows }, (_, rowIndex) => (
            <div
              key={rowIndex}
              style={{ display: "flex", gap: "8px", marginBottom: "40px" }}
            >
              {cards
                .slice(rowIndex * cardsPerRow, (rowIndex + 1) * cardsPerRow)
                .map((deal: any, index: number) => (
                  <DealsCard
                    // eslint-disable-next-line no-useless-escape
                    imageUrl={deal.product_images
                      ?.split("'")[1]
                      ?.replace(/[\[\]']/g, "")
                      ?.trim()}
                    title={deal.post_title}
                    price={deal.product_discounted_price}
                    originalPrice={deal.product_original_price}
                    retailerLogo={deal.retailer}
                    discount={deal.product_discount_percentage}
                    hoverText={"Save Upto"}
                    hoverPercentage={deal.product_discount_percentage}
                    id={deal.product_id}
                    productLink={deal.product_url_retailer}
                  />
                ))}
            </div>
          ))}
        </AccordionDetails>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <AccordionActions>
            {totalProducts > cards.length && (
              <Button
                id="deal-view-more"
                onClick={handleViewMore}
                className="view-more-or-less"
                style={{ width: "200px" }} // Set a fixed width for the button
              >
                {isLoading ? <CircularProgress size={20} /> : "View More Deals"}{" "}
                {/* Show loader in the button */}
              </Button>
            )}
          </AccordionActions>
        </div>
      </Accordion>
    </div>
  );
};

export default DealPagination;
