import React, { useEffect } from "react";
import { Box, Typography, IconButton, Grid, Alert } from "@mui/material";
import "./LoginModal.css";
import pp_logo from "../../assets/pp-logo-new.svg";
import { StyledModal } from "../common/StyledComponents/StyledModal/StyledModal";
import { CloseRounded } from "@mui/icons-material";
/* 
import facebookIcon from "../../assets/facebook-icon.svg";
import googleIcon from "../../assets/google-icon.svg";
import instagramIcon from "../../assets/instagram-icon.svg"; */
import LoginForm from "./LoginForm";
import SignUpModal from "../SignUp/SignUpModal";
import SocialLoginContainer from "./SocialLoginContainer";

export type Props = {
  openModal: boolean;
  handleClose: CallableFunction;
}; /* 

const socialMediaIcons = [
  {
    name: "facebook",
    icon_path: facebookIcon,
  },
  {
    name: "google",
    icon_path: googleIcon,
  },
  {
    name: "instagram",
    icon_path: instagramIcon,
  },
]; */

const LoginModal: React.FC<Props> = (props) => {
  const [openSignUpModal, setOpenSignUpModal] = React.useState<boolean>(false);
  const [isErrorMessage, setIsErrorMessage] = React.useState(false);
  const [resetLoginForm, setResetLoginForm] = React.useState(false);

  const [successMessage, setSuccessMessage] = React.useState<string>();
  useEffect(() => {}, [resetLoginForm]);
  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
      }, 3000); // 3000ms = 3 seconds, you can adjust this to 4000ms for 4 seconds

      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const handleClose = () => {
    props.handleClose(false);
    setIsErrorMessage(false);
  };

  const handleSignUp = () => {
    setOpenSignUpModal(true);
    setResetLoginForm(true);
  };

  const handleSignUpClose = () => {
    setOpenSignUpModal(false);
  };

  /* 
  const CustomSocialMediaIcons = (socialMediaItem: {
    name: any;
    icon_path: string | undefined;
  }) => (
    <IconButton
      disableRipple
      aria-label={`${socialMediaItem.name}-media-icon`}
      sx={{
        mx: 1,
        "&:hover": { backgroundColor: "transparent" },
      }}
    >
      <img
        id={`${socialMediaItem.name}-icon`}
        src={socialMediaItem.icon_path}
        alt={`Login with ${socialMediaItem.name}`}
        className="media-icon-style"
      />
    </IconButton>
  );
 */
  return (
    <div>
      <StyledModal
        open={props.openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={"login-container"}>
          <Grid container display="flex" flexDirection="column" paddingTop={1}>
            {" "}
            <Grid item xs={12} md={12} lg={12} marginBottom={1}>
              <div
                className="flex-row"
                style={{ marginBottom: isErrorMessage ? 0 : 5 }}
              >
                <IconButton
                  disableRipple
                  aria-label="price-pinger-logo-label"
                  sx={{
                    padding: 0,
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                  /*     component={Link}
            to="/home" */
                >
                  <img
                    id="price-pinger-logo"
                    alt="Price Pinger Logo"
                    src={pp_logo}
                    height={"31px"}
                    width={"31px"}
                  ></img>
                </IconButton>
                <Typography className="welcome-text" gutterBottom>
                  Welcome
                </Typography>
                <IconButton
                  disableRipple
                  aria-label="close modal"
                  sx={{
                    padding: 0,
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                  onClick={handleClose}
                >
                  <CloseRounded />
                </IconButton>
              </div>
            </Grid>
            {successMessage && (
              <Grid item xs={12} md={12} lg={12} marginX={3}>
                <Alert
                  severity="success"
                  sx={{
                    fontFamily: "Poppins",
                    padding: "0px",
                    marginBottom: "10px",
                    backgroundColor: "#fff",
                    fontWeight: 600,
                  }}
                >
                  {successMessage}
                </Alert>
              </Grid>
            )}
            <Grid item xs={12} md={12} lg={12}>
              <LoginForm
                handleClose={handleClose}
                isErrorMessage={setIsErrorMessage}
                resetForm={resetLoginForm}
              ></LoginForm>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Typography className="signup-text" gutterBottom>
                Don't have an account?{" "}
                <span className="sign-up-style" onClick={handleSignUp}>
                  Sign up
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  key="or"
                  sx={{
                    width: "50%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                    mr: 1,
                  }}
                />
                <Typography className="or-style">or</Typography>
                <Box
                  sx={{
                    width: "50%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                    ml: 1,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={12} padding={1}>
              {/*   <Box sx={{ display: "flex", justifyContent: "center" }}>
                {socialMediaIcons.map((item) => (
                  <CustomSocialMediaIcons
                    name={item.name}
                    icon_path={item.icon_path}
                  />
                ))}
              </Box> */}
              <SocialLoginContainer />
            </Grid>
          </Grid>
          <SignUpModal
            openSignUpModal={openSignUpModal}
            handleSignUpClose={handleSignUpClose}
            setSuccessMessage={setSuccessMessage}
            handleCloseLogin={handleClose}
          />
        </Box>
      </StyledModal>
    </div>
  );
};

export default LoginModal;
